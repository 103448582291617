import { Autocomplete, TextField } from '@mui/material';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import AppState from '../AppState';

interface IProps {
    ticker?: string;
    onChange: (event: React.SyntheticEvent, value: any) => void;
    disableClearable?: boolean;
    enableInputText?: boolean;
    clearOnBlur?: boolean;
    clearOnEscape?: boolean;
}

export interface SelectTickerOption {
    label: string;
    ticker: string;
}

export function InstrumentsAutocomplete(props: IProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<SelectTickerOption[]>([]);
    useEffect(() => {
        const options: SelectTickerOption[] = AppState.instruments.map((item) => {
            return { label: `${item.ticker} (${item.name})`, ticker: item.ticker, id: item.ticker };
        });

        if (props.ticker) {
            const option = options.find((item) => item.ticker === props.ticker);
            setInputValue(option?.label || '');
        }
        setOptions(sortBy(options, 'label'));
    }, [props]);

    const onChange = (event: React.SyntheticEvent, option: any) => {
        if (option) {
            setInputValue(option);
            props.onChange(event, option);
        } else {
            setInputValue('');
            props.onChange(event, '');
        }
    };

    const onInputChange = useCallback((event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
        if (props.enableInputText) {
            props.onChange(event, newInputValue);
        }
    }, [props]);

    return (
        <Autocomplete
            disablePortal
            disableClearable={props.disableClearable || false}
            id="instruments-combo-box"
            options={options}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            size={'small'}
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => {
                params.inputProps.value = params.inputProps.value || inputValue || '';
                return <TextField {...params} label="Поиск по тикеру" />;
            }}
            clearOnBlur={props.clearOnBlur}
            clearOnEscape={props.clearOnEscape}
        />
    );
}
