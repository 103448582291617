import axios from 'axios';

import { IFibo } from '@ystb/backend/dist/fibo/fiboModel';
import { IUser, IUserProfile } from '@ystb/backend/dist/user/user';
import { IPromoCode, IPromoCodeCreateData } from '@ystb/backend/dist/promoCode/promoCodeModel';
import { API } from '@ystb/backend/dist/api';
import { IRawCandle } from '@ystb/backend/dist/dbCandle/dbCandle';
import { TimeFrame } from '@ystb/backend/dist/common';
import { TradeIdeaModel } from '@ystb/backend/dist/tradeIdea/tradeIdeaModel';
import { ISendingMessagesToTelegramParams } from '@ystb/backend/dist/app.controller';
import { get } from 'lodash';

interface IProps {
    apiHost: string;
}

export class RestClient {
    apiHost: string;

    constructor(props: IProps) {
        this.apiHost = props.apiHost;
    }

    async fetchUserProfile(): Promise<IUserProfile> {
        const response = await axios.get(`${this.apiHost}/${API.users.profile}`, { withCredentials: true });

        if (response.status === 200) {
            return response.data as IUserProfile;
        } else {
            throw new Error(response.status.toString());
        }
    }

    async fetchInstruments() {
        const response = await fetch(`${this.apiHost}/${API.instrument.getAll}`, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async fetchInstrumentsStats() {
        const response = await fetch(`${this.apiHost}/${API.instrument.getAllStats}`, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async fetchTradeIdeas() {
        const response = await fetch(`${this.apiHost}/api/v1/trade_ideas`, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async deactivateTradeIdea(idea: TradeIdeaModel) {
        return axios.post(`${this.apiHost}/${API.tradeIdeas.deactivate}`, { id: idea.id }, { withCredentials: true });
    }

    async fetchFibo(ticker?: string): Promise<IFibo[]> {
        const params: any = {};
        if (ticker) {
            params['ticker'] = ticker;
        }
        const response = await axios.get(`${this.apiHost}/${API.fibo.getFiboModels}`, {
            params,
            withCredentials: true,
        });

        if (response.status === 200) {
            return await response.data;
        } else {
            throw new Error(response.status.toString());
        }
    }

    async requestOTP(nickname: string): Promise<void> {
        const response = await fetch(`${this.apiHost}/api/v1/request_opt`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ nickname }),
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async login(params: { username: string; otp: string }): Promise<string> {
        const response = await axios.post(`${this.apiHost}/${API.session.login}`, params, { withCredentials: true });
        return response.data?.token;
    }

    async logout(): Promise<void> {
        await axios.get(`${this.apiHost}/${API.session.logout}`, { withCredentials: true });
    }

    async getUsersList(): Promise<IUser[]> {
        const response = await fetch(`${this.apiHost}/api/v1/get_users`, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async addUser(user: IUser): Promise<void> {
        return axios.post(`${this.apiHost}/${API.users.addUser}`, user, { withCredentials: true });
    }

    async updateUser(user: IUser): Promise<void> {
        return axios.post(`${this.apiHost}/${API.users.updateUser}`, user, { withCredentials: true });
    }

    async deleteUser(telegram_id: number): Promise<void> {
        return axios.post(`${this.apiHost}/${API.users.deleteUser}`, { telegram_id }, { withCredentials: true });
    }

    async deleteAllOtp(): Promise<void> {
        return axios.post(`${this.apiHost}/api/v1/remove_all_otps`, {}, { withCredentials: true });
    }

    async terminateAllSessions(): Promise<void> {
        return axios.post(`${this.apiHost}/api/v1/terminate_all_sessions`, {}, { withCredentials: true });
    }

    async deleteInstrument(isin: string): Promise<void> {
        return axios.post(`${this.apiHost}/${API.instrument.deleteInstrument}`, { isin }, { withCredentials: true });
    }

    async sendTestTelegramMsg(tgUserId: string): Promise<void> {
        return axios.post(`${this.apiHost}/${API.common.testSendTelegramMsg}`, { tgUserId }, { withCredentials: true });
    }

    async sendTelegramMsg(params: ISendingMessagesToTelegramParams): Promise<void> {
        const data = {
            tgUserId: params.tgUserId,
            msg: params.msg,
            allUsers: params.allUsers,
        };
        return axios.post(`${this.apiHost}/${API.common.sendingMessagesToTelegram}`, data, { withCredentials: true });
    }

    async getAllPromoCodes(): Promise<IPromoCode[]> {
        const response = await fetch(`${this.apiHost}/${API.promoCode.getAll}`, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            throw new Error(response.status.toString());
        }
    }

    async addPromoCode(data: IPromoCodeCreateData): Promise<void> {
        return axios.post(`${this.apiHost}/${API.promoCode.add}`, data, { withCredentials: true });
    }

    async deletePromoCode(code: string): Promise<void> {
        return axios.post(`${this.apiHost}/${API.promoCode.remove}`, { code }, { withCredentials: true });
    }

    async updateInstrumentRatio(ticker: string, ratio: number) {
        return axios.post(
            `${this.apiHost}/${API.instrument.updateRatio}`,
            {
                ticker,
                ratio,
            },
            { withCredentials: true }
        );
    }

    async getCandles(ticker: string, type: TimeFrame, limit: number = 600): Promise<IRawCandle[]> {
        const response = await axios.get(`${this.apiHost}/${API.candles.getCandles}`, {
            params: {
                ticker,
                type,
                limit,
            },
            withCredentials: true,
        });

        if (response.status === 200) {
            return await response.data;
        } else {
            throw new Error(response.status.toString());
        }
    }
}
