import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { IPromoCodeCreateData, PromoCodeModel } from '@ystb/backend/dist/promoCode/promoCodeModel';
// @ts-ignore
import { getHumanReadableAccountType } from '@ystb/backend/dist/user/user.utils';

import AppState from '../AppState';
import { displayDateFormat } from '../constants/common';
import { PromoCodeDialog } from './components/PromoCodeDialog';

let isActivated = false;

export function PromoCodesPage() {
    const [items, setItems] = useState<PromoCodeModel[]>([]);
    const [showPromoCodeModalDialog, setShowPromoCodeModalDialog] = useState(false);

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        AppState.restClient.getAllPromoCodes().then((data) => {
            const models = data.map((model) => new PromoCodeModel(model));
            setItems(models);
        });
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowPromoCodeModalDialog(false);
    }, []);

    const handleDeletePromoCode = useCallback(async (code: string) => {
        try {
            await AppState.restClient.deletePromoCode(code);
            // fixme after adding WS
            window.location.reload();
        } catch {}
    }, []);

    const handleAddPromoCodeButton = useCallback(async () => {
        try {
            setShowPromoCodeModalDialog(true);
        } catch {}
    }, []);

    const onSubmit = useCallback(async ({ data }: { data: IPromoCodeCreateData }) => {
        try {
            await AppState.restClient.addPromoCode(data);
            // fixme after adding WS
            window.location.reload();
        } catch {
            //
        }
    }, []);

    return (
        <>
            <Button variant="outlined" onClick={handleAddPromoCodeButton}>
                Добавить
            </Button>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Код</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell>Действует до</TableCell>
                        <TableCell>Тариф</TableCell>
                        <TableCell>Подписка на (мес.):</TableCell>
                        <TableCell>Использовано</TableCell>
                        <TableCell>Лимит</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.code} hover={true}>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{dayjs(row.createdDate).format(displayDateFormat)}</TableCell>
                            <TableCell>{dayjs(row.expiredDate).format(displayDateFormat)}</TableCell>
                            <TableCell>{getHumanReadableAccountType(row.accountType)}</TableCell>
                            <TableCell>{row.monthCount}</TableCell>
                            <TableCell>{row.total}</TableCell>
                            <TableCell>{row.limit}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="delete"
                                    size={'small'}
                                    onClick={() => handleDeletePromoCode(row.code)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <PromoCodeDialog
                handleCloseModal={handleCloseModal}
                showModalDialog={showPromoCodeModalDialog}
                onSubmit={onSubmit}
            />
        </>
    );
}
