import { get, includes } from 'lodash';
import * as dayjs from 'dayjs';

import { get_now } from '../utils/common';
import { ObjectId } from 'mongodb';

export enum UserRole {
    Undefined = 'undefined',
    Regular = 'regular',
    Admin = 'admin',
    Moderator = 'moderator',
}

export const UserRoleValues = Object.values(UserRole);

export enum UserRiskProfile {
    Undefined = 'undefined',
    Conservative = 'conservative',
    Moderate = 'moderate',
    Aggressive = 'aggressive',
}

export enum AccountType {
    Undefined = 'undefined',
    Investor = 'investor', // 1m
    Trader = 'trader', // 1d
    Speculative = 'speculative', // 1h + 4h
}

const AccountTypeValues = Object.values(AccountType);
const UserRiskProfileValues = Object.values(UserRiskProfile);

export const EMPTY_TELEGRAM_ID = -1;

export interface IUser {
    _id?: ObjectId;
    name: string;
    telegram: string;
    telegram_id: number;
    role?: UserRole;
    eula?: boolean;
    risk_profile?: UserRiskProfile;
    send_personal_notifications?: boolean;
    email?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    account_size?: number;
    account_type?: string;
    account_expired_at?: Date;
    account_activated_at?: Date | string;
    is_short_allowed?: boolean;
    promo_code?: string;
    is_qual_investor?: boolean;
    selected_instruments?: string[];
    send_target_notifications?: boolean;
}

export interface IUserProfile {
    username: string;
    role: UserRole;
    email: string;
    firstName: string;
    lastName: string;
    riskProfile: UserRiskProfile;
}

export class User {
    id?: ObjectId;
    name: string;
    telegram: string;
    telegramId: number;
    role: UserRole;
    eula: boolean;
    riskProfile: UserRiskProfile;
    sendPersonalNotifications: boolean;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    accountSize: number;
    accountType: AccountType;
    accountExpiredAt?: Date;
    accountActivatedAt?: Date;
    isShortAllowed: boolean;
    promoCode: string;
    isQualInvestor: boolean | null;
    selectedInstruments: string[];
    sendTargetNotifications: boolean;

    constructor(data: IUser) {
        let telegramId = data.telegram_id || EMPTY_TELEGRAM_ID;
        if (typeof data.telegram_id === 'string') {
            try {
                telegramId = parseInt(data.telegram_id);
            } catch {
                // do nothing
            }
        }
        this.id = data._id;
        this.name = data.name;
        this.telegram = data.telegram;
        this.telegramId = telegramId;
        this.role = data.role || UserRole.Regular;
        this.eula = data.eula || false;
        this.riskProfile = (data.risk_profile || UserRiskProfile.Undefined) as UserRiskProfile;
        this.sendPersonalNotifications = data.send_personal_notifications || false;
        this.email = data.email || '';
        this.firstName = data.first_name || '';
        this.lastName = data.last_name || '';
        this.middleName = data.middle_name || '';
        this.accountSize = data.account_size || 0;
        this.accountType = (data.account_type || AccountType.Undefined) as AccountType;
        this.accountExpiredAt = new Date(data.account_expired_at);
        this.isShortAllowed = data.is_short_allowed || false;
        this.accountActivatedAt = data.account_activated_at ? new Date(data.account_activated_at) : get_now();
        this.promoCode = data.promo_code || '';
        this.isQualInvestor = typeof data.is_qual_investor === 'boolean' ? data.is_qual_investor : null;
        this.selectedInstruments = data.selected_instruments || [];
        this.sendTargetNotifications = get(data, 'send_target_notifications', true);
    }

    shouldSendMsg(): boolean {
        return this.eula && this.sendPersonalNotifications && this.telegramId && this.telegramId !== EMPTY_TELEGRAM_ID;
    }

    toJson() {
        return {
            name: this.name,
            telegram: this.telegram,
            telegram_id: this.telegramId,
            role: this.role,
            eula: this.eula,
            risk_profile: this.riskProfile,
            send_personal_notifications: this.sendPersonalNotifications,
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            middle_name: this.middleName,
            account_size: this.accountSize,
            account_type: this.accountType,
            account_expired_at: this.accountExpiredAt,
            account_activated_at: this.accountActivatedAt,
            is_short_allowed: this.isShortAllowed,
            promo_code: this.promoCode,
            is_qual_investor: this.isQualInvestor,
            send_target_notifications: this.sendTargetNotifications,
        };
    }

    static isValidModel(model: IUser): boolean {
        const isValidRiskProfile = includes(UserRiskProfileValues, model.risk_profile);
        const isValidUserRole = includes(UserRoleValues, model.role);
        const isValidAccountType = includes(AccountTypeValues, model.account_type);
        return model.name && model.telegram && isValidRiskProfile && isValidUserRole && isValidAccountType;
    }

    isActive(): boolean {
        if (User.isAdminOrModerator(this.role)) {
            return true;
        }
        const now = new Date();
        return this?.accountExpiredAt > now && this.role !== UserRole.Undefined;
    }

    static isActiveUser(userData: IUser): boolean {
        if (User.isAdminOrModerator(userData.role)) {
            return true;
        }
        const expiredAt = userData?.account_expired_at;
        return expiredAt && dayjs().isBefore(expiredAt) && userData.role !== UserRole.Undefined;
    }

    static isAdminOrModerator(role: UserRole): boolean {
        return includes([UserRole.Admin, UserRole.Moderator], role);
    }
}
